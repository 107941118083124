<template>
  <v-card class="pa-2" style="min-height: 90vh; max-height: 90vh; overflow-y: hidden">
    <v-card-title>
      <span>{{ $lang.header[type] }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            :key="0"
            data-cy="tab-entities"
          >
            {{ $lang.header.entities }}
          </v-tab>
          <v-tab
            :key="1"
            data-cy="tab-statements"
          >
            {{ $lang.header.statements }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="fill-height">
          <v-tab-item
            :key="0"
            class="fill-height"
          >
            <div v-if="data && data.data" style="width: 100%; overflow-y: auto" class="py-2 inner-div">
              <template v-for="(itemEntity, i) in data.data.entities">
                <p :key="i + 500" class="color-secondary"><span class="color-primary">{{ $lang.labels.name }}</span>: {{ itemEntity.name }}</p>
                <p :key="i + 600" class="color-secondary">
                  <span class="color-primary">{{ $lang.labels.versionEntity }}</span>: {{ itemEntity.version.entity }},
                  <span class="color-primary">{{ $lang.labels.versionPersistence }}</span>: {{ itemEntity.version.persistence ? itemEntity.version.persistence : $lang.labels.nonExistent }}
                </p>
                <h5 :key="i + 700" class="color-primary mt-4">{{ itemEntity.fields.common.length ? '' : $lang.labels.no }} {{ $lang.header.common }} {{ itemEntity.fields.common.length ? '' : $lang.labels.fields }}</h5>
                <v-data-table
                  v-if="itemEntity.fields.common.length"
                  :key="i + 2000"
                  :headers="headers"
                  :items="itemEntity.fields.common"
                  item-key="id"
                  class="elevation-0 background-transparent"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:item.dataType="{ item }">
                    <div>{{ $lang.status[item.dataType] }}</div>
                  </template>
                  <template v-slot:item.primaryKey="{ item }">
                    <div>{{ item.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.required="{ item }">
                    <div>{{ item.required ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.unique="{ item }">
                    <div>{{ item.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.sortable="{ item }">
                    <div>{{ item.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.searchType="{ item }">
                    <div>{{ $lang.status[item.searchType] }}</div>
                  </template>
                  <template v-slot:item.relation="{ item }">
                    <div>{{ item.relation ? item.relation.entityName : $lang.labels.no }}</div>
                  </template>
                </v-data-table>

                <h5 :key="i + 1200" class="color-primary mt-4">{{ itemEntity.fields.differences.length ? '' : $lang.labels.no }} {{ $lang.header.differences }} {{ itemEntity.fields.differences.length ? '' : $lang.labels.fields }}</h5>
                <v-data-table
                  v-if="itemEntity.fields.differences.length"
                  :key="i + 5000"
                  :headers="headers"
                  :items="itemEntity.fields.differences"
                  item-key="id"
                  class="elevation-0 background-transparent"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:item.dataType="{ item }">
                    <div v-if="item.entity.dataType === item.persistence.dataType">{{ $lang.status[item.entity.dataType] }}</div>
                    <div v-else class="color-error">
                      <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ $lang.status[item.entity.dataType] }}</div>
                      <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ $lang.status[item.persistence.dataType] }}</div>
                    </div>
                  </template>
                  <template v-slot:item.primaryKey="{ item }">
                    <div v-if="item.entity.primaryKey === item.persistence.primaryKey">{{ item.entity.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                    <div v-else class="color-error">
                      <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                      <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                    </div>
                  </template>
                  <template v-slot:item.required="{ item }">
                    <div v-if="item.entity.required === item.persistence.required">{{ item.entity.required ? $lang.labels.yes : $lang.labels.no }}</div>
                    <div v-else class="color-error">
                      <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.required ? $lang.labels.yes : $lang.labels.no }}</div>
                      <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.required ? $lang.labels.yes : $lang.labels.no }}</div>
                    </div>
                  </template>
                  <template v-slot:item.unique="{ item }">
                    <div v-if="item.entity.unique === item.persistence.unique">{{ item.entity.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                    <div v-else class="color-error">
                      <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                      <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                    </div>
                  </template>
                  <template v-slot:item.searchType="{ item }">
                    <div v-if="item.entity.searchType === item.persistence.searchType">{{ $lang.status[item.entity.searchType] }}</div>
                    <div v-else class="color-error">
                      <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ $lang.status[item.entity.searchType] }}</div>
                      <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ $lang.status[item.persistence.searchType] }}</div>
                    </div>
                  </template>
                  <template v-slot:item.relation="{ item }">
                    <div v-if="item.entity.relation === item.persistence.relation">{{ item.entity.relation ? item.entity.relation.entityName : $lang.labels.no }}</div>
                    <div v-else class="color-error">
                      <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.relation ? item.entity.relation.entityName : $lang.labels.no }}</div>
                      <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.relation ? item.persistence.relation.entityName : $lang.labels.no }}</div>
                    </div>
                  </template>
                </v-data-table>

                <h5 :key="i + 1500" class="color-primary mt-4">{{ itemEntity.fields.entity.length ? '' : $lang.labels.no }} {{ $lang.header.entity }} {{ itemEntity.fields.entity.length ? '' : $lang.labels.fields }}</h5>
                <v-data-table
                  v-if="itemEntity.fields.entity.length"
                  :key="i + 2400"
                  :headers="headers"
                  :items="itemEntity.fields.entity"
                  item-key="id"
                  class="elevation-0 background-transparent"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:item.dataType="{ item }">
                    <div>{{ $lang.status[item.dataType] }}</div>
                  </template>
                  <template v-slot:item.unique="{ item }">
                    <div>{{ item.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.primaryKey="{ item }">
                    <div>{{ item.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.searchType="{ item }">
                    <div>{{ $lang.status[item.searchType] }}</div>
                  </template>
                  <template v-slot:item.required="{ item }">
                    <div>{{ item.required ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.relation="{ item }">
                    <div>{{ item.relation ? item.relation.entityName : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.sortable="{ item }">
                    <div>{{ item.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                </v-data-table>

                <h5 :key="i + 1800" class="color-primary mt-4">{{ itemEntity.fields.persistence.length ? '' : $lang.labels.no }} {{ $lang.header.persistence }} {{ itemEntity.fields.persistence.length ? '' : $lang.labels.fields }}</h5>
                <v-data-table
                  v-if="itemEntity.fields.persistence.length"
                  :key="i + 2700"
                  :headers="headers"
                  :items="itemEntity.fields.persistence"
                  item-key="id"
                  class="elevation-0 background-transparent"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:item.dataType="{ item }">
                    <div>{{ $lang.status[item.dataType] }}</div>
                  </template>
                  <template v-slot:item.unique="{ item }">
                    <div>{{ item.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.primaryKey="{ item }">
                    <div>{{ item.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.searchType="{ item }">
                    <div>{{ $lang.status[item.searchType] }}</div>
                  </template>
                  <template v-slot:item.required="{ item }">
                    <div>{{ item.required ? $lang.labels.yes : $lang.labels.no }}</div>
                  </template>
                  <template v-slot:item.relation="{ item }">
                    <div>{{ item.relation ? item.relation.entityName : $lang.labels.no }}</div>
                  </template>
                </v-data-table>
              </template>
            </div>
            <div v-else class="fill-height d-flex justify-center align-center">
              <p>No data to show</p>
            </div>
          </v-tab-item>
          <v-tab-item
            :key="1"
            class="fill-height"
          >
            <div v-if="data && data.data" style="width: 100%; overflow-y: auto" class="py-2 inner-div">
              <template v-for="(item, i) in data.data.statements">
                <p :key="i" class="color-secondary"><span class="color-primary">{{ i + 1 }}</span>: {{ item }}</p>
              </template>
            </div>
            <div v-else class="fill-height d-flex justify-center align-center">
              <p>No data to show</p>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      default: 'VALIDATE'
    },
    errorOrSuccess: {
      type: String,
      default: 'SUCCESS'
    },
    data: {
      type: Object,
      default: () => null
    },
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tab: 0,
      headers: [
        { text: this.$lang.labels.name, value: 'name', sortable: false },
        { text: this.$lang.labels.dataType, value: 'dataType', sortable: false },
        { text: this.$lang.labels.primaryKey, value: 'primaryKey', sortable: false },
        { text: this.$lang.labels.required, value: 'required', sortable: false },
        { text: this.$lang.labels.unique, value: 'unique', sortable: false },
        { text: this.$lang.labels.sortable, value: 'sortable', sortable: false },
        { text: this.$lang.labels.searchType, value: 'searchType', sortable: false },
        { text: this.$lang.labels.relation, value: 'relation', sortable: false }
      ]
    }
  },
  created() {
    if (this.errorOrSuccess === 'SUCCESS' && this.message) {
      this.addSnackbar({
        message: this.message,
        timeout: 5000,
        color: 'success'
      })
    } else if (this.message) {
      this.addSnackbar({
        message: this.message,
        timeout: 5000,
        color: 'error'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar'])

  }
}
</script>
<style lang="scss">
.inner-div {
  max-height: calc(90vh - 150px) !important;
}
</style>
