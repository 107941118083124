<template>
  <v-card class="pa-2 fill-height fill-width custom-bg" flat>
    <v-row wrap no-gutters class="pb-4">
      <v-col v-if="(!valid || !userCanEdit || !isEdit || isDirty) && !lock" cols="12" class="mb-2">
        <v-alert color="info">{{ $lang.hints.saveToShow }}</v-alert>
      </v-col>
      <v-col cols="12" md="6">
        <h4>{{ $lang.labels.persistenceNew }}</h4>
        <v-row no-gutters class="mt-2">
          <v-btn
            :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
            color="info"
            :loading="validateLoading"
            class="color-black"
            data-cy="entity-validate-persistence"
            @click="validatePersistence()"
          >
            {{ $lang.actions.validate }}
          </v-btn>
          <v-btn
            :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
            color="warning"
            :loading="generateLoading"
            class="ml-2 color-black"
            data-cy="entity-generate-persistence"
            @click="generatePersistence()"
          >
            {{ $lang.actions.generate }}
          </v-btn>
          <action-button-with-confirmation
            v-if="isEdit"
            :action-text="$lang.actions.areYouSureYouWantToDelete"
            :action-text-suffix="''"
            :title="$lang.actions.delete"
            type="persistence"
            :is-disabled="!userCanDelete"
            :button-text="$lang.actions.delete"
            :button-color="'error'"
            :data-cy="'persistence-delete'"
            :forced-option="false"
            :trigger-force-logic="triggerForceLogicPersistence"
            :simple-error="simpleError"
            :success-persistence-messages-array="successPersistenceMessagesArray"
            class="ml-2"
            :delete-success="deleteSuccess"
            :currently-open-delete-action="currentlyOpenDeleteAction"
            :delete-instance="2"
            @submit="deletePersistence()"
            @closeDialog="resetValidateGenerateDelete($event)"
            @closeAfterDelete="resetValidateGenerateDelete($event)"
          />
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="mt-4 mt-md-0">
        <h4>{{ $lang.labels.persistenceUpdate }}</h4>
        <v-row no-gutters class="mt-2">
          <v-btn
            :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
            color="info"
            :loading="validateLoadingUpdate"
            class="color-black"
            data-cy="entity-validate-persistence"
            @click="validatePersistenceUpdate()"
          >
            {{ $lang.actions.validate }}
          </v-btn>
          <v-btn
            :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
            color="warning"
            :loading="generateLoadingUpdate"
            class="ml-2 color-black"
            data-cy="entity-generate-persistence"
            @click="generatePersistenceUpdate()"
          >
            {{ $lang.actions.generate }}
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mt-4 mb-2">
        <v-row no-gutters>
          <v-col cols="12" class="mb-2">
            <h4>{{ $lang.labels.resources }}</h4>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex">
              <p>{{ $lang.labels.resourceType }}</p>
              <v-btn
                small
                class="ml-2"
                data-cy="entity-resource-type-select-all"
                @click="selectedResourceTypes.length < 3 ? selectedResourceTypes = [...resourceTypes] : selectedResourceTypes = []"
              >
                {{ btnNameTypes }}
              </v-btn>
            </div>
            <template v-for="(item, index) in formattedResourceTypes">
              <v-checkbox
                :key="index"
                v-model="selectedResourceTypes"
                :label="item.text"
                :value="item.value"
                :disabled="!userCanEdit"
                dense
                data-cy="entity-resource-type"
              ></v-checkbox>
            </template>
          </v-col>
          <v-col cols="12" md="6" class="pl-md-2">
            <div class="d-flex">
              <p>{{ $lang.labels.resourceAction }}</p>
              <v-btn
                small
                class="ml-2"
                data-cy="entity-resource-action-select-all"
                @click="selectedResourceActions.length < 3 ? selectedResourceActions = [...resourceActions] : selectedResourceActions = []"
              >
                {{ btnNameActions }}
              </v-btn>
            </div>
            <template v-for="(item, index) in formattedResourceActions">
              <v-checkbox
                :key="index"
                v-model="selectedResourceActions"
                :label="item.text"
                :value="item.value"
                :disabled="!userCanEdit"
                dense
                data-cy="entity-resource-action"
              ></v-checkbox>
            </template>
          </v-col>
          <v-col cols="12">
            <v-row no-gutters>
              <v-btn
                :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty || (selectedResourceActions.length < 1 || selectedResourceTypes.length < 1)"
                color="info"
                :loading="resourceValidateLoading"
                class="color-black"
                data-cy="entity-validate-resource"
                @click="resourceValidateFunction()"
              >
                {{ $lang.actions.validate }}
              </v-btn>
              <v-btn
                :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty || (selectedResourceActions.length < 1 || selectedResourceTypes.length < 1)"
                color="warning"
                :loading="resourceGenerateLoading"
                class="ml-2 color-black"
                data-cy="entity-generate-resource"
                @click="resourceGenerateFunction()"
              >
                {{ $lang.actions.generate }}
              </v-btn>
              <action-button-with-confirmation
                v-if="isEdit"
                :action-text="$lang.actions.areYouSureYouWantToDelete"
                :action-text-suffix="''"
                :title="$lang.actions.delete"
                type="resources"
                :is-disabled="!userCanDelete"
                :button-text="$lang.actions.delete"
                :button-color="'error'"
                :data-cy="'resources-delete'"
                :forced-option="true"
                :trigger-force-logic="triggerForceLogicResources"
                :success-resources-messages-array="successResourcesMessagesArray"
                class="ml-2"
                :currently-open-delete-action="currentlyOpenDeleteAction"
                :delete-instance="3"
                :delete-success="deleteSuccess"
                @submit="deleteResources($event)"
                @closeDialog="resetValidateGenerateDelete($event)"
                @closeAfterDelete="resetValidateGenerateDelete($event)"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-if="showValidateGenerateModal" v-model="showValidateGenerateModal" max-width="80%">
      <validate-generate-result-modal
        :type="validateGenerateType"
        :error-or-success="errorOrSuccess"
        :data="validateGenerateData"
        :message="validateGenerateMessage"
        @closeDialog="resetValidateGenerateModal()"
      />
    </v-dialog>
    <v-dialog v-if="showResourceValidateGenerateModal" v-model="showResourceValidateGenerateModal" max-width="80%">
      <resource-validate-generate-result-modal
        :type="validateGenerateType"
        :error-or-success="errorOrSuccess"
        :data="validateGenerateData"
        :message="validateGenerateMessage"
        @closeDialog="resetValidateGenerateModal()"
        @openResource="openResource($event)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import {
  entityPersistenceGenerateUsingPOST as generatePersistence,
  entityPersistenceValidateUsingGET as validatePersistence,
  entityResourcesValidateUsingPOST as validateResources,
  entityResourcesGenerateUsingPOST as generateResources,
  entityPersistenceValidateUpdateUsingGET as validateUpdatePersistence,
  entityPersistenceExecuteUpdateUsingPUT as executeUpdatePersistence
} from '@/utils/api'
import ValidateGenerateResultModal from '@/pages/entities/ValidateGenerateResultModal.vue'
import ResourceValidateGenerateResultModal from '@/pages/entities/ResourcesValidateGenerateResultModal.vue'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import { definitions } from '@/utils/definitions'

export default {
  name: 'GenerationCard',
  components: {
    ValidateGenerateResultModal,
    ResourceValidateGenerateResultModal,
    ActionButtonWithConfirmation
  },
  props: {
    entityId: {
      type: Number,
      default: null
    },
    valid: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    },
    userCanEdit: {
      type: Boolean,
      default: false
    },
    userCanDelete: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    simpleError: {
      type: String,
      default: ''
    },
    successPersistenceMessagesArray: {
      type: Array,
      default: () => []
    },
    successResourcesMessagesArray: {
      type: Array,
      default: () => []
    },
    triggerForceLogicPersistence: {
      type: Boolean,
      default: false
    },
    triggerForceLogicResources: {
      type: Boolean,
      default: false
    },
    currentlyOpenDeleteAction: {
      type: Number,
      default: 0
    },
    deleteSuccess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      resourcesTypes: {
        GLOBAL_TRANSLATION: 'templates/translations/edit',
        MODULE: 'modules/local/edit',
        PROCESS: 'processes/edit',
        PROCESS_CREDENTIAL: 'credentials/edit',
        SETTING: 'settings/edit',
        TEXT_TEMPLATE: 'templates/edit',
        TRIGGER_CRON: 'triggers/cron/edit',
        TRIGGER_REST: 'triggers/rest/edit',
        VALIDATION_RULE: 'validations/edit',
        PLUGIN: 'plugins/edit',
        USER: 'permissions/users/edit',
        TRIGGER_MESSAGING: 'triggers/messaging/edit',
        STORAGE: 'storages/edit',
        TRIGGER_EVENT_HANDLER: 'triggers/event-handler/edit',
        ENTITY: 'entities/edit',
        LIBRARY: 'libraries/edit'
      },
      showValidateGenerateModal: false,
      showResourceValidateGenerateModal: false,
      validateLoading: false,
      generateLoading: false,
      validateLoadingUpdate: false,
      generateLoadingUpdate: false,
      errorOrSuccess: 'SUCCESS',
      validateGenerateType: 'VALIDATE',
      validateGenerateData: null,
      validateGenerateMessage: '',
      err: '',
      selectedResourceTypes: [],
      selectedResourceActions: [],
      resourceValidateLoading: false,
      resourceGenerateLoading: false,
      resourceTypes: definitions.ResourceAction.properties.resourceType.enum,
      resourceActions: definitions.ResourceAction.properties.actionType.enum
    }
  },
  computed: {
    btnNameTypes() {
      return this.selectedResourceTypes && this.selectedResourceTypes.length < 3 ? this.$lang.actions.selectAll : this.$lang.actions.selectNone
    },
    btnNameActions() {
      return this.selectedResourceActions && this.selectedResourceActions.length < 3 ? this.$lang.actions.selectAll : this.$lang.actions.selectNone
    },
    selectedResources() {
      return this.selectedResourceTypes.map((x) =>
        this.selectedResourceActions.map((y) => ({
          actionType: y,
          resourceType: x
        })
        )).flat()
    },
    formattedResourceTypes() {
      return this.resourceTypes.map((item) => ({
        text: this.$lang.status[item],
        value: item
      }
      ))
    },
    formattedResourceActions() {
      return this.resourceActions.map((item) => ({
        text: this.$lang.status[item],
        value: item
      }))
    }
  },
  methods: {
    openResource(resourceTypeObject) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[resourceTypeObject.resource.resourceType]}/${resourceTypeObject.resource[resourceTypeObject.type].id}`, '_blank')
    },
    resetValidateGenerateModal() {
      this.showValidateGenerateModal = false
      this.showResourceValidateGenerateModal = false
      this.errorOrSuccess = 'SUCCESS'
      this.validateGenerateType = 'VALIDATE'
      this.validateGenerateData = null
      this.validateGenerateMessage = ''
    },
    handleResponse(res, type, loading, showModal) {
      this.validateGenerateType = type
      if (res.status !== 200) {
        if (res.response.status === 403) {
          this.err = this.$lang.errors.noPermission
        } else if (/^4\d{2}$/.test(res.response.status)) { // other 4xx status codes
          this.validateGenerateMessage = res.response.data.statusText
        } else {
          this.validateGenerateMessage = res.response.statusText
        }
        this.validateGenerateData = res.response.data
        this.errorOrSuccess = 'ERROR'
      } else {
        this.validateGenerateData = res.data
        this.validateGenerateMessage = res.statusText
        this.errorOrSuccess = 'SUCCESS'
      }
      this[loading] = false
      this[showModal] = true
    },
    handleError(loading, errorType) {
      this[loading] = false
      this.err = this.$lang.errors[errorType]
      setTimeout(() => this.err = '', 5000)
    },
    initializePersistenceHandling(loading) {
      this.resetValidateGenerateModal()
      this.err = ''
      this[loading] = true
    },
    validatePersistence() {
      this.initializePersistenceHandling('validateLoading')
      validatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'VALIDATE', 'validateLoading', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('validateLoading', 'entityValidation'))
    },
    generatePersistence() {
      this.initializePersistenceHandling('generateLoading')
      generatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'GENERATE', 'generateLoading', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('generateLoading', 'entityGenerate')
        )
    },
    validatePersistenceUpdate() {
      this.initializePersistenceHandling('validateLoadingUpdate')
      validateUpdatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'VALIDATE', 'validateLoadingUpdate', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('validateLoading', 'entityValidation'))
    },
    generatePersistenceUpdate() {
      this.initializePersistenceHandling('generateLoadingUpdate')
      executeUpdatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'GENERATE', 'generateLoadingUpdate', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('generateLoading', 'entityGenerate')
        )
    },
    resourceValidateFunction() {
      this.err = ''
      this.resourceValidateLoading = true
      validateResources({ id: this.entityId, body: { resources: this.selectedResources } })
        .then((res) =>
          this.handleResponse(res, 'VALIDATE', 'resourceValidateLoading', 'showResourceValidateGenerateModal')
        )
        .catch(() =>
          this.handleError('resourceValidateLoading', 'entityValidation'))
    },
    resourceGenerateFunction() {
      this.err = ''
      this.resourceGenerateLoading = true
      generateResources({ id: this.entityId, body: { resources: this.selectedResources } })
        .then((res) =>
          this.handleResponse(res, 'GENERATE', 'resourceGenerateLoading', 'showResourceValidateGenerateModal'))
        .catch(() =>
          this.handleError('resourceGenerateLoading', 'entityGenerate'))
    },
    deletePersistence() {
      this.$emit('deletePersistenceFunct', this.entityId)
    },
    deleteResources(isForceDelete) {
      this.$emit('deleteResourcesFunct', isForceDelete, this.entityId)
    },
    resetValidateGenerateDelete(e) {
      if (this.currentlyOpenDeleteAction !== 1) {
        this.$emit('resetValidateGenerateDelete', e)
      }
    }
  }
}
</script>
